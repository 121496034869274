<script setup lang="ts">
  import { isHolidaySeason, useScreenSize } from '~/shared/lib'
  import { PortalLogo } from '~/shared/ui/svg'

  import { LanguageSwitcher } from '~/features/locale'
  import { SearchInput } from '~/features/search'

  // eslint-disable-next-line boundaries/element-types
  import { DynamicSearch } from '~/widgets/dynamicSearch'

  import BurgerMenu from './BurgerMenu.vue'
  import UserMenu from './UserMenu.vue'
  const props = defineProps<{
    modelValue: boolean
  }>()

  const emits = defineEmits<{
    (e: 'toggleBurger', value: boolean): void
  }>()
  const route = useRoute()
  const { fromDesktopMedium, isMobile } = storeToRefs(useScreenSize())
  const showBurger = computed(() => !fromDesktopMedium.value)
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const showSearch = computed(() => route.name === 'profiles-page')

  const burgerIsActive = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emits('toggleBurger', value)
    }
  })
  const searchInput = ref('')
  const searchHandler = (searchString: string) => {
    if (searchString === '' || searchString.length > 1) {
      handleBlur()
      if (searchString) {
        navigateTo({ name: 'profiles-page', query: { search: searchString } })
      } else {
        navigateTo({ name: 'profiles-page' })
      }
    }
  }
  const handleClickOnLogo = () => {
    navigateTo('/')
  }
  const searchInputInFocus = ref(false)
  const handleFocus = () => {
    searchInputInFocus.value = true
  }
  const handleBlur = () => {
    searchInputInFocus.value = false
  }
</script>

<template>
  <div class="default-layout__header-wrapper">
    <div class="default-layout__header-container">
      <BurgerMenu
        v-if="showBurger"
        v-model="burgerIsActive"
        class="default-layout__header-burger"
      />

      <div
        class="default-layout__logo-container"
        @click="handleClickOnLogo"
      >
        <img
          v-if="isHolidaySeason()"
          src="~/assets/img/logoChristmasHat.png"
          class="default-layout__logo-christmas"
        />

        <PortalLogo class="default-layout__logo-icon" />
        <span class="default-layout__logo-text">{{ $t('header_title') }} <b>Uplatform</b></span>
      </div>

      <div
        v-if="!isMobile && !showSearch"
        class="default-layout__search"
      >
        <SearchInput
          v-model="searchInput"
          class="default-layout__search"
          @search="searchHandler"
          @focus="handleFocus()"
        />
        <DynamicSearch
          :search-query="searchInput"
          :search-input-in-focus="searchInputInFocus"
        />
      </div>
      <LanguageSwitcher
        v-if="!isMobile"
        class="default-layout__language-switcher"
      />
      <UserMenu class="default-layout__user-menu" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .default-layout {
    &__header {
      &-wrapper {
        @include flex-container(row, center, center);
        background-color: $purple;
        width: 100%;
        padding: 12px 0;
        z-index: 4;
        height: 64px;
      }
      &-container {
        padding: 0 40px 0 34px;
        max-width: 1280px;
        width: 100%;
        @include flex-container(row, flex-start, center);
      }
      &-burger {
        margin-right: 25px;
      }
    }
    &__logo {
      &-wrapper {
        @include flex-container(row, flex-start, center);
        flex-wrap: wrap;
        gap: 12px;
      }
      &-container {
        @include flex-container(row, flex-start, center);
        gap: 12px;
        cursor: pointer;
        position: relative;
      }
      &-christmas {
        position: absolute;
        left: -4px;
        top: -7px;
        width: 16px;
      }
      &-text {
        @include font-montserrat-500(15px, 18px, $white-1);
        width: 166px;
        word-break: break-word;
        white-space: normal;
        b {
          @include font-montserrat-800(15px, 18px, $white-1);
        }
      }
    }
    &__search {
      position: relative;
      max-width: 272px;
      width: 100%;

      &-input {
        margin-right: 12px;
        margin-left: 25px;

        width: 100%;
      }
    }
    &__language-switcher {
      margin-left: auto;
    }
    &__user-menu {
      margin-left: 17px;
    }
  }
  @include mobile {
    .default-layout {
      &__header {
        &-container {
          padding: 0 16px;
          max-width: unset;
          width: 100%;
          justify-content: space-between;
        }
        &-wrapper {
          position: sticky;
          top: 0px;
        }
      }
    }
  }
</style>
