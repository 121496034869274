<script setup lang="ts">
  import { isHolidaySeason, isValentineSeason, useScreenSize } from '~/shared/lib'
  import {
    HomeIcon,
    NewsIcon,
    EventsIcon,
    ShopIcon,
    FaqIcon,
    FeedbackIcon,
    SignoutIcon,
    InfoIcon,
    EmployeeIcon,
    OrdersIcon,
    TagsIcon
  } from '~/shared/ui/svg'

  import { useProfile } from '~/entities/profile'

  import { LanguageSwitcher } from '~/features/locale'

  // eslint-disable-next-line boundaries/element-types
  import { DynamicSearch } from '~/widgets/dynamicSearch'
  import { TheHearts, TheSnowflakes } from '~/widgets/layout'

  import SidebarItem from './SidebarItem.vue'
  const emits = defineEmits<{
    (event: 'close-sidebar'): void
  }>()
  const { isAdmin, isContentManager, isShopManager } = storeToRefs(useProfile())

  const SearchInput = defineAsyncComponent(async () => {
    const module = await import('~/features/search')
    return module.SearchInput
  })

  const UserBaseInfo = defineAsyncComponent(async () => {
    const module = await import('~/entities/profile')
    return module.UserBaseInfo
  })
  const { t } = useI18n()

  const navigation = computed(() => {
    return [
      {
        groupName: '',
        items: [
          {
            name: t('navigation_item_home'),
            icon: HomeIcon,
            path: '/',
            show: true
          }
        ]
      },
      {
        groupName: t('navigation_group_company'),
        items: [
          {
            name: t('navigation_item_news'),
            icon: NewsIcon,
            path: '/news',
            show: true
          },
          {
            name: t('navigation_item_announcements'),
            icon: EventsIcon,
            path: '/events',
            show: true
          },
          {
            name: t('navigation_item_employees'),
            icon: EmployeeIcon,
            path: '/profiles',
            show: true
          }
        ]
      },
      {
        groupName: t('navigation_group_personal'),
        items: [
          {
            name: t('navigation_item_profile'),
            icon: InfoIcon,
            path: '/info',
            show: true
          },
          {
            name: t('navigation_item_shop'),
            icon: ShopIcon,
            path: '/shop',
            show: true
          },
          {
            name: t('navigation_item_orders'),
            icon: OrdersIcon,
            path: '/my-orders',
            show: true
          },
          {
            name: t('navigation_item_tags'),
            icon: TagsIcon,
            path: '/tags-control',
            show: isAdmin.value || isShopManager.value
          }
        ]
      },
      {
        groupName: t('navigation_group_hr'),
        items: [
          {
            name: t('navigation_item_faq'),
            icon: FaqIcon,
            path: '/faq',
            show: true
          },
          {
            name: t('navigation_item_feedback'),
            icon: FeedbackIcon,
            path: '/feedback',
            show: true
          },
          {
            name: t('navigation_item_feedback_control'),
            icon: FeedbackIcon,
            path: '/control-feedback',
            show: isAdmin.value || isContentManager.value
          },
          {
            name: t('navigation_item_roles_control'),
            icon: EmployeeIcon,
            path: '/roles-control',
            show: isAdmin.value
          }
        ]
      },
      {
        groupName: '',
        items: [
          {
            name: t('base_signout'),
            icon: SignoutIcon,
            path: '/signout',
            show: isMobile.value
          }
        ]
      }
    ]
  })

  const { isMobile, isTablet } = storeToRefs(useScreenSize())
  const searchHandler = (searchString: string) => {
    if (searchString === '' || searchString.length > 1) {
      handleBlur()
      if (searchString) {
        navigateTo({ name: 'profiles-page', query: { search: searchString } })
      } else {
        navigateTo({ name: 'profiles-page' })
      }
      if (isMobile.value) {
        emits('close-sidebar')
      }
    }
  }
  const searchInput = ref('')
  const searchInputInFocus = ref(false)
  const handleFocus = () => {
    searchInputInFocus.value = true
  }
  const handleBlur = () => {
    searchInputInFocus.value = false
  }

  const showSnowflakes = computed(() => {
    if (!isTablet.value) {
      return false
    }
    return isHolidaySeason()
  })

  const showHearts = computed(() => {
    if (!isTablet.value) {
      return false
    }
    return isValentineSeason()
  })
</script>

<template>
  <q-scroll-area
    class="sidebar__container"
    :bar-style="{ borderRadius: '10px', background: '#F3F3F3', opacity: '1', width: '4px' }"
    :thumb-style="{ borderRadius: '10px', background: '#818181', opacity: '20%', width: '4px' }"
  >
    <TheSnowflakes v-if="showSnowflakes" />
    <TheHearts v-if="showHearts" />
    <div class="sidebar__wrapper">
      <UserBaseInfo v-if="isMobile" />
      <div
        v-if="isMobile"
        class="sidebar__search-wrapper"
      >
        <SearchInput
          v-model="searchInput"
          class="sidebar__search"
          @search="searchHandler"
          @focus="handleFocus()"
        />
        <DynamicSearch
          :search-query="searchInput"
          search-page
          :search-input-in-focus="searchInputInFocus"
          @select-item="emits('close-sidebar')"
        />
      </div>

      <div
        v-for="segment in navigation"
        class="sidebar__segment"
      >
        {{ segment.groupName }}
        <template
          v-for="item in segment.items"
          :key="item.path"
        >
          <SidebarItem
            v-if="item.show"
            :to="item.path"
            @handle-navigate="$emit('close-sidebar')"
          >
            <template #icon>
              <component :is="item.icon" />
            </template>
            <template #name> {{ item.name }} </template>
          </SidebarItem>
        </template>
      </div>
      <LanguageSwitcher
        v-if="isMobile"
        class="sidebar__language-switcher"
      />
    </div>
  </q-scroll-area>
</template>

<style scoped lang="scss">
  .sidebar {
    &__container {
      position: relative;
      flex-shrink: 0;
      width: 175px;
      height: calc(100vh - 64px);
      height: calc(100svh - 64px);
      z-index: 4;
    }
    &__wrapper {
      width: 100%;
      height: 100%;
      padding-top: 34px;
      @include flex-container(column, flex-start, flex-start);
      gap: 17px;
    }
    &__segment {
      @include font-montserrat-400(14px, 17px, $dark-grey);
      @include flex-container(column, flex-start, flex-start);
      gap: 4px;
      width: 100%;
    }
  }
  @include desktop-medium {
    .sidebar {
      &__container {
        position: absolute;
        left: 0;
        background-color: $grey;
        width: 200px;
      }
      &__wrapper {
        width: 100%;
        padding: 34px 12px 0 20px;
      }
    }
  }
  @include mobile {
    .sidebar {
      &__container {
        position: fixed;
        padding-bottom: 0;
        top: 64px;
        width: 100%;
        height: calc(100vh + 25px);
        height: calc(100svh + 25px);
      }
      &__wrapper {
        padding-top: 25px;
        width: 100%;
        align-items: stretch;
        padding-bottom: 100px;
      }
      &__language-switcher {
        margin-top: auto;
        color: $black;
      }
      &__search-wrapper {
        position: relative;
        width: 100%;
      }
    }
  }
</style>
